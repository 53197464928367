body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root {
  --pixel-offset: 0px;
  --tab-container-color: #c4cef0;
   
}
@media screen and (max-width: 576px) {
  :root {
    --pixel-offset: 1px;
     
  }
}

.app-main-canvas{
  background-color: var(--main-color);
  width: 100vw;
  height: 100vh;
}

.app-top-bar{
  position: sticky;
  z-index: 2;
  top: 0;
  width: 100%;
  min-height: 18vh;
  max-height: 18vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  font-size: 2vw;
  background-color: #DEF5E5;
  font-size: 32px;
}

.app-tab-container{
  position: sticky;
  z-index: 2;
  top: 0;
  width: 100%;
  height: 5vh;
  background-color: var(--tab-container-color);
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  border: 2px solid gray;
  border-left: 0;
  border-right: 0;
}
.app-tab {
  position: relative;
  width: max(15vw, 80px);
  height: 4vh;
  top: calc(0.5vh + 2px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--tab-color);
  margin-right: 4vw;
  margin-left: 4vw;
  /* Ensures that the skewed sides don't overflow */

  /* Added the following properties to center the content vertically */
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  border-bottom: 2px solid gray;
  border-left: 0;
  border-right: 0;
  box-sizing: border-box;
}

.app-tab::before,
.app-tab::after {
  content: "";
  position: absolute;
  top: 0;
  height: 4vh;
  background-color: var(--tab-color);
  z-index: -1; /* Places the pseudo-elements behind the content */

  box-sizing: border-box;
  
}

.app-tab::before {
  left: -12%; /* Adjust the value to control the skew */
  width: 50%;
  top: -2px;
  border-radius: 10px 10px 0 0 ;
  transform: skew(-20deg);
  transform-origin: 0 0; /* Skew from the top-left corner */
  border: 2px solid var(--bottom-border-color);  /*c4cef0*/
  border-bottom: 2px solid var(--alter-bottom-border-color);
  height:  calc(4vh -   var(--pixel-offset));
  box-sizing: border-box;
}

.app-tab::after {
  right: -12%; /* Adjust the value to control the skew */
  width: 50%;
  top: -2px;
  border-radius: 10px 10px 0 0 ;
  transform: skew(20deg);
  transform-origin: 100% 0; /* Skew from the top-right corner */
  border: 2px solid var(--bottom-border-color);
  border-bottom: 2px solid var(--alter-bottom-border-color);
  height: calc(4vh -  var(--pixel-offset));
  box-sizing: border-box;
}


.app-content-container{
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  box-sizing: border-box;
  padding: 3vh 3vw 3vh 3vw;
}